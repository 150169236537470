import Lottie from 'react-lottie';
// import logo from '@src/assets/images/logo/logo.png'
import { useSkin } from '@hooks/useSkin';
import animationDataLightTheme from '@src/assets/images/the-club/loader/data_white_bg.json';
import animationDataDarkTheme from '@src/assets/images/the-club/loader/data_black_bg.json';

// ** Logo
const SpinnerComponent = () => {
  const [skin, _] = useSkin();

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData:
      skin === 'light' ? animationDataLightTheme : animationDataDarkTheme,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  return (
    <div className='fallback-spinner vh-100'>
      {/* <img className='fallback-logo' src={logo} alt='logo' /> */}
      <div className='h-100 d-flex align-items-center'>
        <Lottie options={defaultOptions} height={400} width={400} />
      </div>
      {/* <div className='loading'> */}

      {/* <div className='effect-1 effects'></div> */}
      {/* <div className='effect-2 effects'></div>
        <div className='effect-3 effects'></div> */}
      {/* </div> */}
    </div>
  );
};

export default SpinnerComponent;
